<template>
  <div class="page">
    <el-form :inline="true" :model="form" style="margin-top: 20px">
      <el-form-item label="原分类:">
        <el-select
            v-model="form.primary_cate_id"
            clearable
            placeholder="请选择"
        >
          <el-option
              v-for="item in goodsCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="目标分类:">
        <el-select
            v-model="form.target_cate_id"
            clearable
            placeholder="请选择"
        >
          <el-option
              v-for="item in goodsCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="startExecution" type="primary" >开始执行</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {apiGetGoodsOptions, apiMoveCategory} from "@/request/api";

export default {
  components: {},
  props: [],
  data() {
    return {
      goodsCategoryList: [],
      form: {
        primary_cate_id: '',
        target_cate_id: '',
      },
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getOption();
  },
  mounted() {},
  methods: {
    getOption(){
      apiGetGoodsOptions().then(res => {
        if(res.code == 200){
          this.goodsCategoryList = res.data.goodsCate;
        }
      })
    },
    startExecution(){
      apiMoveCategory({source_id:this.form.primary_cate_id,target_id:this.form.target_cate_id}).then(res => {
          this.$message.success(res.message);
      })
    }
  }
}

</script>
<style>
  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;

  }
</style>
